/*-------------------------------------------------main-----------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-clip: border-box;
  scrollbar-width: none;
}

img {
  transition: 1s;
  opacity: 0.3;
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
}
img:hover {
  opacity: 1;
}

section::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
}

#home {
  background-color: #aaaaaa;
}

#services {
  background-color: #aaaaaa;
}

#work {
  background-color: #aaaaaa;
}

#about {
  background-color: #aaaaaa;
}

#contact {
  background-color: #aaaaaa;
}

h1 {
  z-index: 99;
  mix-blend-mode: difference;
  color: #D1D1D1;
  text-align: center;
  font-family: Helvetica;
  font-style: normal;
  text-transform: uppercase;
}

h2 {
  z-index: 99;
  mix-blend-mode: difference;
  color: #D1D1D1;
  text-align: center;
  font-family: Helvetica;
  font-style: normal;
  text-transform: uppercase;
}

h3 {
  z-index: 99;
  mix-blend-mode: difference;
  color: #D1D1D1;
  text-align: center;
  font-family: Helvetica;
  font-style: normal;
  text-transform: uppercase;
}

p {
  z-index: 99;
  mix-blend-mode: difference;
  color: #D1D1D1;
  text-align: center;
  font-family: Helvetica;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 4.97813rem */
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.Frame1 {
  transition: 1.2s;
  background-color: white;
}
.Frame1:hover {
  background-color: rgb(212, 212, 212);
}

.Frame2 {
  transition: 1.2s;
  background-color: white;
}
.Frame2:hover {
  background-color: rgb(212, 212, 212);
}

.Frame3 {
  transition: 1.2s;
  background-color: white;
}
.Frame3:hover {
  background-color: rgb(212, 212, 212);
}/*# sourceMappingURL=App.css.map */