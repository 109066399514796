/*-------------------------------------------------work-----------------------------------------------*/
@media only screen and (max-width: 1920px) {
  .WorkH {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 50px;
    font-size: 1.5rem;
    mix-blend-mode: difference;
    font-weight: 50;
  }
  .partP {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 50px;
    font-size: 1.1rem;
    mix-blend-mode: difference;
    text-align: center;
  }
  .outer {
    z-index: 1;
    width: 100%;
    height: 50%;
    position: absolute;
    transform-origin: 0 0;
    left: 0;
    top: 25%;
  }
  .cont {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .outer .inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;
    scrollbar-width: none;
  }
  .inner::-webkit-scrollbar {
    display: none;
  }
  .outer .inner div.boxproject {
    position: relative;
    place-items: center;
    transition: 1.2s;
    margin: 0 1px 0 0;
    background-color: white;
    border-radius: 16px;
    width: 500px;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .outer .inner div.boxproject:hover {
    background-color: rgb(212, 212, 212);
  }
  .partnerlogo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 250px;
  }
  .border_2_1 {
    transition: 1.2s;
    margin: 0 1px 0 0;
    background-color: white;
    border-radius: 16px;
    width: 10%;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .border_2_1:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_2_2 {
    transition: 1.2s;
    margin: 0 1px 0 0;
    background-color: white;
    border-radius: 16px;
    width: 10%;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .border_2_2:hover {
    background-color: rgb(212, 212, 212);
  }
  #work_1 {
    width: 9.98%;
    height: 24.9%;
    left: 90%;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #work_2 {
    width: 9.95%;
    height: 24.9%;
    left: 0;
    top: 75.1%;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #work_3 {
    width: 89.95%;
    height: 24.9%;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #work_4 {
    width: 89.9%;
    height: 24.9%;
    left: 10%;
    top: 75.1%;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .WorkH {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 30px;
    font-size: 1.2rem;
    mix-blend-mode: difference;
    font-weight: 50;
  }
  .WorkP {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 30px;
    font-size: 0.7rem;
    mix-blend-mode: difference;
    text-align: center;
  }
  .outer {
    z-index: 1;
    width: 60%;
    height: 100%;
    position: absolute;
    transform-origin: 0 0;
    left: 20%;
    top: 0;
  }
  .cont {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .outer .inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    scrollbar-width: none;
  }
  .inner::-webkit-scrollbar {
    display: none;
  }
  .outer .inner div.boxproject {
    position: relative;
    place-items: center;
    overflow: hidden;
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 40%;
  }
  .outer .inner div.boxproject:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_2_1 {
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 15%;
  }
  .border_2_1:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_2_2 {
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 15%;
    float: left;
    flex: 0 0 auto;
  }
  .border_2_2:hover {
    background-color: rgb(212, 212, 212);
  }
  #work_1 {
    width: 19.9%;
    height: 100%;
    left: 80.1%;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 8px;
  }
  #work_2 {
    width: 19.9%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 8px;
  }
  #work_3 {
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 0;
  }
  #work_4 {
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 0;
  }
}
@media only screen and (max-width: 480px) {
  .partnerlogo {
    width: 150px;
  }
}/*# sourceMappingURL=Projects.css.map */