input{ font-size: 20px ;touch-action: none; border-style: none; outline: none; background-color:rgb(229, 229, 229);}
textarea{ font-size: 20px ;touch-action: none; border-style: none; outline: none; background-color:rgb(229, 229, 229);}

.text {
    color: #D1D1D1;
    text-align: center;
    font-family: Helvetica;
    font-style: normal;
    text-transform: uppercase;
    

}
/*-------------------------------------------------about-----------------------------------------------*/

@media only screen and (max-width: 1920px) {
   
    .emailcontant {
      
       
        padding:0 1.5em;
        margin: 0 auto;
    }
    .consultTitel {
        top: 33%;
        left: 52%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;

    }
   

    .consultDis {
        top: 38%;
        left: 52%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
        max-width: 30rem;
       
    }

// ===============================================================================================
    .emailForm {
        z-index: 1;
        width: 400px; height: 50%; left: 480px; top: 280px; position: absolute;  ;

        
        
    }
    .form{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        
        
    }
    .fildeTiltel{
        color:#9a9a9a;
        font-family: Helvetica;

    font-style: normal;

    }
    .filde{
       
        padding: 0.7rem;
        border-radius: 0.5rem;
      
 
    }
    .message{
        height: 5.5rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        
    }

    // input[type=submit] {
    //     background-color:white;
    //     color:black;
    //     margin-top: 2rem;
    //     padding: 0.8rem;
    //     border-radius: 0.5rem;
    //     font-size: 20px ;
        
    //     &:hover {
    //         cursor: pointer;
    //         background-color:rgb(191, 191, 191);
    //     }
    // }
    
    .sendButton{
       
        background-color:rgb(167, 167, 167);
        color:rgb(36, 36, 36);
        margin-top: 2rem;
        padding: 0.8rem;
        border-radius: 0.5rem;
        &:hover {
            cursor: pointer;
           
            background-color:rgb(123, 123, 123);
        
        }
        
    }
 
// =================================================================
    
    #about_1 {
        width: 415px; height: 368px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px; 
    }

    #about_2 {
        width: 693px; height: 601px; left: 369px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px ; 
    }

    #about_3 {
        width: 900px; height: 567px; left: 971px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px;
    }

    #about_4 {
        width: 206px; height: 381px; left: 1539px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 601px; left: 369px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 368px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 381px; left: 1539px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

}
@media only screen and (max-width: 1600px) {
    .emailcontant {
      
       
        padding:0 1.5em;
        margin: 0 auto;
    }
    .consultTitel {

        left: 55%;


    }
   

    .consultDis {
        top: 38%;
        left: 55%;

       
    }

    // ===============================================================================================
    .emailForm {
        z-index: 1;
        width: 400px; height: 50%; left: 310px; top: 300px; position: absolute;  ;
        
    }
    
// =================================================================
    #about_1 {
        width: 415px; height: 210.21px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 693px; height: 598.75px; left: 211.21px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 206px; height: 598.75px; left: 211.21px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 900px; height: 564.88px; left: 810.96px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 223.16px; left: 1376.84px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 210.21px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 223.16px; left: 1376.84px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
}
@media only screen and (max-width: 1440px) {
    .emailcontant {
      
       
        padding:0 1.5em;
        margin: 0 auto;
    }
    .consultTitel {

        left: 55%;


    }
   

    .consultDis {
        top: 38%;
        left: 55%;

       
    }

    // ===============================================================================================
    .emailForm {
        z-index: 1;
        width: 400px; height: 50%; left: 230px; top: 300px; position: absolute;  ;
        
    }

   
// =================================================================
    #about_1 {
        width: 415px; height: 128px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 693px; height: 601px; left: 129px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 206px; height: 601px; left: 129px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 900px; height: 567px; left: 731px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 141px; left: 1299px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 128px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 141px; left: 1299px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
}
@media only screen and (max-width: 1200px) {
    .emailcontant {
      
       
        padding:0 1.5em;
        margin: 0 auto;
    }
    .consultTitel {

        left: 55%;


    }
   

    .consultDis {
        top: 38%;
        left: 55%;

       
    }

    // ===============================================================================================
    .emailForm {
        z-index: 1;
        width: 400px; height: 50%; left: 100px; top: 300px; position: absolute;   ; 
        
    }
  
// =================================================================
    #about_1 {
        width: 693px; height: 617px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 206px; height: 617px; left: 0px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 900px; height: 582px; left: 618px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 1024px) {
    .emailcontant {
        padding:0 1.5em;
        margin: 0 auto;
    }
    .consultTitel {
        top: 77%;
        left: 10%;
        font-size: 1.5rem;
    }
   

    .consultDis {
        top: 80%;
        left: 10%;
        font-size: 0.8rem;

    }

    
    // ===============================================================================================
    .emailForm {
        z-index: 1;
        
        width: 60%; height: 50% ; left: 22%; top: 150px; position: absolute;


    }
   
// =================================================================
    #about_1 {
        width: 1024px; height: 644px; left: 1024px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 1024px; height: 255px; left: 1024px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 768px) {

// =================================================================
    #about_1 {
        width: 768px; height: 644px; left: 768px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
        }

    #about_2 {
        width: 768px; height: 255px; left: 768px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 624px) {
    // ===============================================================================================
.emailForm {
    z-index: 1;
    
    width: 80%; height: 50% ; left: 10%; top: 150px; position: absolute;

}
   
// =================================================================
    #about_1 {
        width: 624px; height: 644px; left: 624px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 624px; height: 255px; left: 624px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 480px) {


    .consultDis {
        margin-right: 10%;
    }

// =================================================================
    #about_1 {
        width: 480px; height: 644px; left: 480px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 480px; height: 255px; left: 480px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}