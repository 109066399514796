/*-------------------------------------------------about-----------------------------------------------*/

@media only screen and (max-width: 1920px) {
   
    .aboutcontant {
      
       
        padding:0 1.5em;
        margin: 0 auto;
    }
    .name {
        top: 30%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;

    }
    .noteabout {
        top: 8%;
        left: 20%;
        position: absolute;

        z-index: 99;

        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: center;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        font-size: 4rem;
        mix-blend-mode: difference;
    
    }

    .dis {
        top: 38%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
        max-width: 30rem;
       
    }

    .team {
        top: 60%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;

    }
    .dis2 {
        top: 65%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
        max-width: 30rem;
       
    }
    .boit {
        top: 30%;
        left: 54%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;

    }
    .dis3 {
        top: 38%;
        left: 54%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
        max-width: 30rem;
    }
// ===============================================================================================
    .outerAbout {
        z-index: 1;
        width: 568px; height: 900px; left: 971px; top: 0px; position: absolute;  ;
        
    }
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        display: flex;
        flex-flow:  column;
        overflow:hidden;
    }
    
   .innerAbout {
        // overflow-y: auto;
        scrollbar-width: none;
        overflow-y:auto;
        overflow-x:hidden;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
   
    
    
    .bordertopabout {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 99.8%;
        height: 23%;
        float: left;
    }
    .borderbottomabout {

        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 99.8%;
        height: 23%;
        float: left;
    }
    .outerAbout .innerAbout div.boxabout {
        
        // transform: rotate(-90deg);
        position: relative; 
        
        // place-items: center;
        overflow: hidden;
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 100%;
        height: 30.94%; //30.94%
        float: right
    }
// =================================================================
    
    #about_1 {
        width: 415px; height: 368px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 693px; height: 601px; left: 369px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 900px; height: 567px; left: 971px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 206px; height: 381px; left: 1539px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 601px; left: 369px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 368px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 381px; left: 1539px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

}
@media only screen and (max-width: 1600px) {
    
    .name {
        top: 30%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
    }
    .position {
        top: 35%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal ;
        text-transform: uppercase;
        letter-spacing: .15rem;
    }
    .dis {
        top: 38%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
       
    }

    .team {
        top: 60%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
    }
    .dis2 {
        top: 65%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
       
    }
    .boit {
        top: 30%;
        left: 54%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1.5rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
    }
    .dis3 {
        top: 38%;
        left: 54%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
       
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;
        width: 566px; height: 900px; left: 811px; top: 0px; position: absolute;  ;
        
    }
    
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
    }
    
    .innerAbout {
        // overflow-y: auto;
        overflow-y:auto;
        overflow-x:hidden;
        width: 100%; 
        height: 100%; 
        
        background-color: #4c4c4c;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    

    .outerAbout .innerAbout div.boxabout {
        
        // transform: rotate(-90deg);
        position: relative; 
        
        // place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 100%;
        height: 30.94%;
        float: right
    }
// =================================================================
    #about_1 {
        width: 415px; height: 210.21px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 693px; height: 598.75px; left: 211.21px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 206px; height: 598.75px; left: 211.21px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 900px; height: 564.88px; left: 810.96px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 223.16px; left: 1376.84px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 210.21px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 223.16px; left: 1376.84px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
}
@media only screen and (max-width: 1440px) {
    .noteabout{
        left: 15%;
    }
    .name {
        left: 15%;
    }
    .position {
        left: 15%;
    }
    .dis {
        left: 15%;
       
       
    }

    .team {
        left: 15%;
    }
    .dis2 {
        left: 15%;
        
       
    }

    .dis3 {

    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;
      
        width: 568px; height: 900px; left: 731px; top: 0px; position: absolute;  ;
        overflow: hidden;
    }
   

    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
       
        
    }
   
    .innerAbout {
        // overflow-y: auto;
        scrollbar-width: none;
        overflow-y:auto;
        overflow-x:hidden;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
      }
    
    
    .outerAbout .innerAbout div.boxabout {
        
        // transform: rotate(-90deg);
        position: relative; 
        
        // place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 100%;
        height: 30.94%;
        float: right
    }
// =================================================================
    #about_1 {
        width: 415px; height: 128px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 693px; height: 601px; left: 129px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 206px; height: 601px; left: 129px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 900px; height: 567px; left: 731px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_5 {
        width: 206px; height: 141px; left: 1299px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_6 {
        width: 484px; height: 128px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
    #about_7 {
        width: 693px; height: 141px; left: 1299px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }
}
@media only screen and (max-width: 1200px) {
    .noteabout{
        left: 10%;
    }
    .name {
        top: 30%;
        left: 10%;
        font-size: 1.5rem;

    }
    .position {
        top: 35%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis {
        top: 38%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }

    .team {
        top: 60%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis2 {
        top: 65%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }
    .boit {
        top: 30%;
        left: 60%;
        font-size: 1.5rem;
    }
    .dis3 {
        top: 38%;
        left: 60%;

        font-size: .8rem;
        max-width: 25rem;
       
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;
 
        width: 580px; height: 900px; left: 618px; top: 0px; position: absolute;   ;
        
    }
    
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
    }
    
    .outerAbout .innerAbout {
        // overflow-y: auto;
        scrollbar-width: none;
        overflow-y:auto;
        overflow-x:hidden;
        width: 100%; 
        height: 100%; 
       
        background-color: #4c4c4c;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    
    
    .outerAbout .innerAbout div.boxabout {
        
        // transform: rotate(-90deg);
        position: relative; 
        // display: grid;
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  1px 1px 0;
        background-color: white;
        border-radius: 16px;
        width: 100%;
        height: 30.94%;
        float: right
    }
// =================================================================
    #about_1 {
        width: 693px; height: 617px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_2 {
        width: 206px; height: 617px; left: 0px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_3 {
        width: 900px; height: 582px; left: 618px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0;  border-radius: 16px
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 1024px) {
    .noteabout {
        top: 2%;
        left: 10%;
        font-size: 3rem;
    }

    .name {
        top: 20%;
        left: 10%;
        font-size: 1.5rem;

    }
    .position {
        top: 35%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis {
        top: 25%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }

    .team {
        top: 40%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis2 {
        top: 45%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }
    .boit {
        top: 80%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis3 {
        top: 85%;
        left: 10%;

        font-size: .8rem;
        max-width: 25rem;
       
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;

        width: 1024px; height: 256px;  left: 0px; top: 645px; position: absolute;    ;
        
    }
    
    
    .outerAbout .innerAbout {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
        
    }
    .bordertopabout {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;

    }
    .borderbottomabout {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerAbout .innerAbout div.boxabout {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #about_1 {
        width: 1024px; height: 644px; left: 1024px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 1024px; height: 255px; left: 1024px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 768px) {
    .noteabout {
        top: 2%;
        left: 10%;
        
    }
    .name {
        top: 20%;
        left: 10%;
        font-size: 1.5rem;

    }
    .position {
        top: 35%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis {
        top: 25%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }

    .team {
        top: 40%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis2 {
        top: 45%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }
    .boit {
        top: 80%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis3 {
        top: 85%;
        left: 10%;

        font-size: .8rem;
        max-width: 25rem;
       
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;

        width: 769px; height: 256px; left: 0px; top: 645px; position: absolute;  ;
        
    }
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        // white-space: nowrap; 
        
    }
    .outerAbout::-webkit-scrollbar{
        display: none;
    }
    .outerAbout .innerAbout {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    .bordertop {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: left;
        flex: 0 0 auto;

    }
    .borderbottom {

        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerAbout .innerAbout div.boxabout {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #about_1 {
        width: 768px; height: 644px; left: 768px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
        }

    #about_2 {
        width: 768px; height: 255px; left: 768px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 624px) {
    .noteabout {
        top: 2%;
        left: 10%;
        font-size: 3rem;
    }
    .name {
        top: 20%;
        left: 10%;
        font-size: 1.5rem;

    }
    .position {
        top: 35%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis {
        top: 25%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }

    .team {
        top: 40%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis2 {
        top: 45%;
        left: 10%;
        font-size: .8rem;
        max-width: 25rem;
       
    }
    .boit {
        top: 80%;
        left: 10%;
        font-size: 1.5rem;
    }
    .dis3 {
        top: 85%;
        left: 10%;

        font-size: .8rem;
        max-width: 25rem;
       
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;

        width: 625px; height: 256px; left: 0px; top: 645px; position: absolute;  ;
        
    }
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        // white-space: nowrap; 
        
    }
    
    .outerAbout .innerAbout {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    .bordertop {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 0%;
        height: 100%;
        float: left;
        flex: 0 0 auto;

    }
    .borderbottom {

        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 0%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerAbout .innerAbout div.boxabout {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 85%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #about_1 {
        width: 624px; height: 644px; left: 624px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 624px; height: 255px; left: 624px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 480px) {
    .noteabout {
        top: 2%;
        left: 10%;
        font-size: 2.5rem;
    }
    // ===============================================================================================
    .outerAbout {
        z-index: 1;

        width: 481px; height: 256px; left: 0px; top: 645px; position: absolute;   ;
        
    }
    .aboutcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        white-space: nowrap; 
        
    }
    
    .outerAbout .innerAbout {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerAbout::-webkit-scrollbar {
        display: none;
    }
    .bordertop {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 0%;
        height: 100%;
        float: left;
        flex: 0 0 auto;

    }
    .borderbottom {

        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 0%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerAbout .innerAbout div.boxabout {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 85%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #about_1 {
        width: 480px; height: 644px; left: 480px; top: 644px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_2 {
        width: 480px; height: 255px; left: 480px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0;  border-radius: 8px
    }

    #about_3 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_5 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #about_6 {
        width: 0px; height: 0px; opacity: 0%;
    }
    #about_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
}