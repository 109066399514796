/*-------------------------------------------------contact-----------------------------------------------*/



@media only screen and (max-width: 1920px) {
    .contactcontant {
      
        max-width: 60rem;
        padding:0 1.5em;
        margin: 0 auto;
    }
    .notecontact {
        top: 8%;
        left: 20%;
        position: absolute;

        z-index: 99;

        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: center;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        font-size: 4rem;
        mix-blend-mode: difference;
    
    }
    .info {
        top: 30%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    
        
    }
    .info2 {
        top: 50%;
        left: 22%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    
        
    }
    .info3 {
        top: 20%;
        left: 55%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: .9rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    
        
    }
    .SM {
        top: 50%;
        left: 55%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: .9rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    }
    
    .foter {
        top: 88%;
        left: 40%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: center;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    }
    // ===============================================================================================
    .conatctInfo {
        margin: 50px;
        font-size: .8rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .15rem;
    }
    .outerContact {
        z-index: 1;
        width: 602px; height: 504px; left: 369px; top: 198px; position: absolute;  ;
        
    }
    
    .contactcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        display: flex;
        flex-flow:  column;
        overflow:hidden;
    }
    
   .innerContact {
        // overflow-y: auto;
        scrollbar-width: none;
        overflow-y:auto;
        overflow-x:hidden;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
    }
    .innerContact::-webkit-scrollbar {
        display: none;
    }
   
    
    
    .bordertopcontact {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 0 0 0;
        background-color: white;
        border-radius: 16px;
        // width: 100%;
        // height: 23%;
        width: 0px;
        height: 0px;
        float: left;
    }
    .borderbottomcontact {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0  0 0 0;
        background-color: white;
        border-radius: 16px;
        // width: 100%;
        // height: 23%;
        width: 0px;
        height: 0px;
        float: left;
    }
    .outerContact .innerContact div.boxcontact {
        
        // transform: rotate(-90deg);
        position: relative; 
        
        // place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0.5px  1px 0.5px 0;
        background-color: white;
        border-radius: 16px;
        width: 100%;
        height: 40%; //40%
        float: right
    }
// =================================================================
    #contact_1 {
        width: 415px; height: 368px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_2 {
        width: 206px; height: 381px; left: 1539px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_3 {
        width: 484px; height: 368px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_4 {
        width: 693px; height: 381px; left: 1539px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_5 {
        width: 504px; height: 601px; left: 369px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_6 {
        width: 197px; height: 601px; left: 369px; top: 197px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_7 {
        width: 702px; height: 567px; left: 971px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_8 {
        width: 197px; height: 1169px; left: 369px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
        }
}


@media only screen and (max-width: 1600px) {
    .foter {

        left: 38%;
       

    }
    .note2 {
        left: 16%;
        top: 8%;
     
        position: absolute;

        z-index: 1;

        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: center;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;
        font-size: 4rem;
        mix-blend-mode: difference;
    }
    .info {
        top: 30%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    
        
    }
    .info2 {
        top: 50%;
        left: 16%;
        position: absolute;
        display: flex;
        z-index: 99;
        font-size: 1rem;
        mix-blend-mode: difference ;
        color: #D1D1D1;
        text-align: left;
        font-family: Helvetica;
        font-style: normal;
        text-transform: uppercase;

        letter-spacing: .15rem;
    
        
    }
    .info3 {
        top: 20%;
        left: 55%;
      
    
        
    }
    
    .SM {
        top: 50%;
        left: 55%;
      
    }
// ===============================================================================================
     .outerContact {

        width: 599.5px; left: 212px; height: 504px;
        
    }

// =================================================================
    #contact_1 {
        width: 415px; height: 210.87px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_2 {
        width: 206px; height: 223.02px; left: 1376.98px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_3 {
        width: 484px; height: 210.87px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_4 {
        width: 693px; height: 223.02px; left: 1376.98px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_5 {
        width: 504px; height: 598.63px; left: 211.87px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_6 {
        width: 197px; height: 598.63px; left: 211.87px; top: 197px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_7 {
        width: 702px; height: 564.53px; left: 811.45px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_8 {
        width: 197px; height: 1163.91px; left: 212.08px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
        }
}
@media only screen and (max-width: 1440px) {
    .notecontact{
        left: 15%;
    }
    .info {
        top: 30%;
        left: 15%;
        
    }
    .info2 {
        top: 50%;
        left: 15%;
        
    }
    .info3 {
        top: 20%;
        left: 55%;
      
    
        
    }
    
    .SM {
        top: 50%;
        left: 55%;
      
    }

    .foter {

        left: 37%;
       

    }
    // ===============================================================================================
    .outerContact {

        width: 602px; height: 495.5px; left: 129px; top: 207px;
        
    }

// =================================================================
    #contact_1 {
        width: 415px; height: 128px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_2 {
        width: 495px; height: 601px; left: 129px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_3 {
        width: 206px; height: 601px; left: 129px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_4 {
        width: 702px; height: 567px; left: 731px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_5 {
        width: 206px; height: 141px; left: 1299px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_6 {
        width: 484px; height: 128px; left: 0px; top: 484px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_7 {
        width: 693px; height: 141px; left: 1299px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_8 {
        width: 197px; height: 1169px; left: 129px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
        }
}
@media only screen and (max-width: 1200px) {
    .notecontact{
        left: 10%;
    }
    .info {
        top: 30%;
        left: 10%;
        

    }
    .info2 {
        top: 45%;
        left: 10%;
    
    }
    .info3 {
        top: 20%;
        left: 55%;
      
    
        
    }
    
    .SM {
        top: 50%;
        left: 55%;
      
    }
    .foter {

        left: 35%;
    }
     // ===============================================================================================
     .outerContact {

        width: 617.5px; left: 0px; 
        
    }

// =================================================================
    #contact_1 {
        width: 495px; height: 617px; left: 0px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_2 {
        width: 702px; height: 582px; left: 618px; top: 702px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }
    #contact_3 {
        width: 197px; height: 1200px; left: 0px; top: 900px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_4 {
        width: 206px; height: 617px; left: 0px; top: 206px; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; border-radius: 16px
    }

    #contact_5 {
        width: 0px; height: 0px; opacity: 0%;
        }

    #contact_6 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_8 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 1024px) {
    .notecontact{
        top: 2%;
        left: 10%;
        font-size: 3rem;
    }
    .info {
        top: 15%;
        left: 10%;

    }
    .info2 {
        top: 35%;
        left: 10%;
        top: 2%;
        left: 10%;
        font-size: 3rem;

    }
    .info3 {
        top: 15%;
        left: 10%;
        font-size: 0.8rem;
    }

    
    .SM {
        
        top: 68%;
        left: 10%;
        font-size: 0.8rem;
      
    }

    .foter {

        left: 32%;

    }
     // ===============================================================================================
     .outerContact {
        z-index: 1;
        width: 1024px; height: 201px;  left: 0px; top: 362px; position: absolute; 
        
    }
    .outerContact .innerContact {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerContact::-webkit-scrollbar {
        display: none;
    }
    
    .contactcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
        
    }
    .bordertopcontact {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;

    }
    .borderbottomcontact {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerContact .innerContact div.boxcontact {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #contact_1 {
        width: 1024px; height: 361px;  left: 1024px; top: 361px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_2 {
        width: 1024px; height: 179px; left: 1024px; top: 742px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }
    #contact_3 {
        width: 1024px; height: 157px; left: 1024px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #contact_5 {
        width: 0px; height: 0px; opacity: 0%;
        }

    #contact_6 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_8 {
        width: 0px; height: 0px; opacity: 0%;
        }
}

@media only screen and (max-width: 768px) {
    .notecontact {
        top: 2%;
        left: 10%;
        
    }
    .foter {
 
        left: 25%;
    }
    
    .info3 {
        top: 15%;
        left: 10%;
        font-size: 0.8rem;
    }

    
    .SM {
        
        top: 68%;
        
      
    }
    // ===============================================================================================
    .outerContact {
        z-index: 1;

        width: 768px; height: 201px;  left: 0px; top: 362px; position: absolute; 
        
    }
    .outerContact .innerContact {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerContact::-webkit-scrollbar {
        display: none;
    }
    
    .contactcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
        
    }
    .bordertopcontact {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;

    }
    .borderbottomcontact {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerContact .innerContact div.boxcontact {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #contact_1 {
        width: 768px; height: 361px;  left: 768px; top: 361px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_2 {
        width: 768px; height: 179px; left: 768px; top: 742px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }
    #contact_3 {
        width: 768px; height: 157px; left: 768px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #contact_5 {
        width: 0px; height: 0px; opacity: 0%;
        }

    #contact_6 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_8 {
        width: 0px; height: 0px; opacity: 0%;
        }

}

@media only screen and (max-width: 624px) {
    .notecontact {
        top: 2%;
        left: 10%;
        font-size: 3rem;
    }
    .info {
        top: 20%;
        left: 10%;
        

    }
    .info2 {
        top: 35%;
        left: 10%;
        

    }
    
    .SM {
        top: 67%;
        left: 10%;
      
    }
    .foter {
     
        left: 19%;
      
    }
    // ===============================================================================================
    .outerContact {
        z-index: 1;

        width: 624px; height: 201px;  left: 0px; top: 362px; position: absolute; 
        
    }
    .outerContact .innerContact {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerContact::-webkit-scrollbar {
        display: none;
    }
    
    .contactcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
        
    }
    .bordertopcontact {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;

    }
    .borderbottomcontact {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 18%;
        height: 100%;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerContact .innerContact div.boxcontact {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #contact_1 {
        width: 624px; height: 361px;  left: 624px; top: 361px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px  
    }

    #contact_2 {
        width: 624px; height: 179px; left: 624px; top: 742px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
     }
    #contact_3 {
        width: 624px; height: 157px; left: 624px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #contact_5 {
        width: 0px; height: 0px; opacity: 0%;
        }

    #contact_6 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_8 {
        width: 0px; height: 0px; opacity: 0%;
        }
}
@media only screen and (max-width: 480px) {
    .notecontact {
        top: 2%;
        left: 10%;
        font-size: 2.5rem;
    }
    .info {
  
        font-size: .8rem;

    }
    .info2 {
  
        font-size: .8rem;

    }
    .info3 {
        top: 15%;
        left: 10%;
        font-size: .8rem;
    }
    
    .foter {
      
        font-size: .8rem;
        left: 16%;
      
    }
    // ===============================================================================================
    .outerContact {
        z-index: 1;

        width: 480px; height: 201px;  left: 0px; top: 362px; position: absolute; 
        
    }
    .outerContact .innerContact {
        display: flex;
        overflow-y:hidden;
        overflow-x:auto;
        width: 100%; 
        height: 100%; 
        background-color: #4c4c4c;
        scrollbar-width: none;
        
    }
    .innerContact::-webkit-scrollbar {
        display: none;
    }
    
    .contactcont {
        position: relative;
        width: 100%; 
        height: 100%; 
        
        
    }
    .bordertopcontact {
        
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 0;
        height: 0;
        float: right;
        flex: 0 0 auto;

    }
    .borderbottomcontact {
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        margin: 0 1px 0 0;
        background-color: white;
        border-radius: 10px;
        width: 0;
        height: 0;
        float: right;
        flex: 0 0 auto;
   
        
    }
    .outerContact .innerContact div.boxcontact {
        

        position: relative; 
        
        place-items: center;
        overflow: hidden;
      
        transition: 1.2s;
        &:hover {
            background-color: rgb(212, 212, 212);
        }
        
        margin: 0 1px 0  0;
        background-color: white;
        border-radius: 10px;
        width: 90%;
        height: 100%;
        float:left;
        flex: 0 0 auto;
    }
// =================================================================
    #contact_1 {
        width: 480px; height: 361px;  left: 480px; top: 361px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_2 {
        width: 480px; height: 179px; left: 480px; top: 742px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }
    #contact_3 {
        width: 480px; height: 157px; left: 480px; top: 900px; position: absolute; transform: rotate(-180deg); transform-origin: 0 0; border-radius: 8px
    }

    #contact_4 {
        width: 0px; height: 0px; opacity: 0%;
    }

    #contact_5 {
        width: 0px; height: 0px; opacity: 0%;
        }

    #contact_6 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_7 {
        width: 0px; height: 0px; opacity: 0%;
        }
    #contact_8 {
        width: 0px; height: 0px; opacity: 0%;
        }
}