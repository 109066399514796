/*-------------------------------------------------services-----------------------------------------------*/
@media only screen and (max-width: 1920px) {
  .note {
    top: 8%;
    left: 20%;
    position: absolute;
    z-index: 1;
    mix-blend-mode: difference;
    color: #D1D1D1;
    text-align: center;
    font-family: Helvetica;
    font-style: normal;
    text-transform: uppercase;
    font-size: 4rem;
    mix-blend-mode: difference;
  }
  .note2 {
    top: 8%;
    left: 20%;
    position: absolute;
    z-index: 99;
    mix-blend-mode: difference;
    color: #D1D1D1;
    text-align: center;
    font-family: Helvetica;
    font-style: normal;
    text-transform: uppercase;
    font-size: 4rem;
    mix-blend-mode: difference;
  }
  .ServH {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 50px;
    font-size: 1.5rem;
    mix-blend-mode: difference;
    font-weight: 50;
  }
  .ServP {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 50px;
    font-size: 0.8rem;
    mix-blend-mode: difference;
    text-align: center;
  }
  .outer {
    z-index: 1;
    width: 100%;
    height: 50%;
    position: absolute;
    transform-origin: 0 0;
    left: 0;
    top: 25%;
  }
  .cont {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .outer .inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;
  }
  .inner::-webkit-scrollbar {
    display: none;
  }
  .outer .inner div.boxaservice {
    position: relative;
    place-items: center;
    transition: 1.2s;
    margin: 0px 1px 0px 0px;
    background-color: white;
    border-radius: 16px;
    width: 500px;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .outer .inner div.boxaservice:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_1_1 {
    transition: 1.2s;
    margin: 0px 1px 0px 0px;
    background-color: white;
    border-radius: 16px;
    width: 10%;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .border_1_1:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_1_2 {
    transition: 1.2s;
    margin: 0px 1px 0px 0px;
    background-color: white;
    border-radius: 16px;
    width: 10%;
    height: 100%;
    float: left;
    flex: 0 0 auto;
  }
  .border_1_2:hover {
    background-color: rgb(212, 212, 212);
  }
  #services_1 {
    width: 9.98%;
    height: 24.9%;
    left: 90%;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #services_2 {
    width: 9.95%;
    height: 24.9%;
    left: 0;
    top: 75.1%;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #services_3 {
    width: 89.95%;
    height: 24.9%;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
  #services_4 {
    width: 89.9%;
    height: 24.9%;
    left: 10%;
    top: 75.1%;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 16px;
  }
}
@media only screen and (max-width: 1440px) {
  .note {
    left: 15%;
  }
  .note2 {
    left: 15%;
  }
}
@media only screen and (max-width: 1200px) {
  .note {
    left: 10%;
  }
  .note2 {
    left: 10%;
  }
}
@media only screen and (max-width: 1024px) {
  .note {
    top: 45%;
    left: -1%;
    font-size: 3rem;
    transform: rotate(-90deg);
  }
  .note2 {
    top: 2%;
    left: 10%;
    font-size: 3rem;
  }
  .ServH {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 30px;
    font-size: 1.2rem;
    mix-blend-mode: difference;
    font-weight: 50;
  }
  .ServP {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 30px;
    font-size: 0.7rem;
    mix-blend-mode: difference;
    text-align: center;
  }
  .outer {
    z-index: 1;
    width: 60%;
    height: 100%;
    position: absolute;
    transform-origin: 0 0;
    left: 20%;
    top: 0;
  }
  .cont {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .outer .inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    scrollbar-width: none;
  }
  .inner::-webkit-scrollbar {
    display: none;
  }
  .outer .inner div.boxaservice {
    position: relative;
    place-items: center;
    overflow: hidden;
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 40%;
  }
  .outer .inner div.boxaservice:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_1_1 {
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 15%;
  }
  .border_1_1:hover {
    background-color: rgb(212, 212, 212);
  }
  .border_1_2 {
    transition: 1.2s;
    margin: 1px 0 0 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    height: 15%;
    float: left;
    flex: 0 0 auto;
  }
  .border_1_2:hover {
    background-color: rgb(212, 212, 212);
  }
  #services_1 {
    width: 19.9%;
    height: 100%;
    left: 80.1%;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 8px;
  }
  #services_2 {
    width: 19.9%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 8px;
  }
  #services_3 {
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 0;
  }
  #services_4 {
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    position: absolute;
    transform: 0;
    transform-origin: 0 0;
    border-radius: 0;
  }
}
@media only screen and (max-width: 768px) {
  .note {
    top: 45%;
    left: -5%;
    font-size: 3rem;
    transform: rotate(-90deg);
  }
  .note2 {
    top: 2%;
    left: 10%;
    font-size: 3rem;
  }
}
@media only screen and (max-width: 624px) {
  .note {
    top: 45%;
    left: -5%;
    font-size: 2rem;
    transform: rotate(-90deg);
  }
  .note2 {
    top: 2%;
    left: 10%;
    font-size: 2rem;
  }
}
@media only screen and (max-width: 480px) {
  .note {
    top: 45%;
    left: -8%;
    font-size: 2rem;
    transform: rotate(-90deg);
  }
  .note2 {
    top: 2%;
    left: 10%;
    font-size: 2rem;
  }
}/*# sourceMappingURL=Services.css.map */